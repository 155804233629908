export default defineAppConfig({
  showTeaserDescription: {
    large: false,
    medium: false,
    small: false,
    tiny: false,
    default: false,
    ordered: false,
  },

  byline: {
    safeOneLine: true,
  },

  authentification: {
    dk: {
      showPianoLogin: false,
      showCognitoLogin: true,
    },
    nl: {
      showPianoLogin: true,
      showCognitoLogin: false,
    },
    se: {
      showPianoLogin: false,
      showCognitoLogin: true,
    },
    no: {
      showPianoLogin: false,
      showCognitoLogin: true,
    },
    fi: {
      showPianoLogin: false,
      showCognitoLogin: true,
    },
    en: {
      showPianoLogin: false,
      showCognitoLogin: false,
    },
  },

  teaserItem: {
    extraLarge: {
      labelOnImage: true,
      showTeaserDescription: false,
      showImage: true,
    },
    large: {
      labelOnImage: true,
      showTeaserDescription: true,
      showImage: true,
    },
    medium: {
      labelOnImage: true,
      showTeaserDescription: false,
      showImage: true,
    },
    small: {
      labelOnImage: true,
      showTeaserDescription: false,
      showImage: true,
    },
    tiny: {
      labelOnImage: false,
      showTeaserDescription: false,
      showImage: false,
    },
    default: {
      labelOnImage: true,
      showTeaserDescription: false,
      showImage: true,
    },
    ordered: {
      labelOnImage: false,
      showTeaserDescription: false,
      showImage: false,
    },
    video: {
      labelOnImage: true,
      showTeaserDescription: false,
      showImage: true,
    },
  },

  teaserlist: {
    theme: {
      hasBackground: false,
    },
    ordered: {
      hasBackground: 'contrast',
      items: '3',
    },
    slider: {
      hasBackground: 'themed',
    },
    presentation: {
      hasBackground:
        'contrast' /** values false 'contrast' 'themed' and (image is always working if it's present in the api)*/,
    },
    default: {
      hasBackground: false,
    },
    latestNews: {
      hasBackground: 'themed',
    },
    sliderVideo: {
      hasBackground: 'themed',
      fullWidth: true,
    },
    featured: {
      hasBackground: true,
    },
  },
  useHeroBanner: false,
  isPrimaryTextAlignCentered: false,
  withTeaserCategories: false,
  showBurgerMenu: true,
  showLogin: true,
  fallbackHeroImage: true,
  hasShortHeaderLogo: false,
  page: {
    category: {
      layout: 'default',
      bannerTemplate: 'onlyFirst',
      darkmode: false,
      hasStickyBanner: false,
    },
    frontpage: {
      layout: 'default',
      bannerTemplate: 'onlyFirst',
      darkmode: false,
      hasStickyBanner: false,
    },
    video: {
      layout: 'video',
      bannerTemplate: 'default',
      darkmode: false,
      hasStickyBanner: false,
    },
    authors: {
      layout: 'default',
      bannerTemplate: 'default',
      darkmode: false,
      hasStickyBanner: false,
    },
    signup: {
      layout: 'signup',
      bannerTemplate: 'default',
      darkmode: false,
      hasStickyBanner: false,
    },
    login: {
      layout: 'login',
      bannerTemplate: 'default',
      darkmode: false,
      hasStickyBanner: false,
    },
    profile: {
      layout: 'profile',
      bannerTemplate: 'default',
      darkmode: false,
      hasStickyBanner: false,
    },
    favourites: {
      layout: 'favourites',
      bannerTemplate: 'default',
      darkmode: false,
      hasStickyBanner: false,
    },
  },
  category: {
    layout: 'default',
    bannerTemplate: 'onlyFirst',
    hasStickyBanner: false,
  },
  siteSpecific: {
    brands: {
      bom: {
        icon: {
          tag: 'bolig-magasinet',
          name: 'bom_icon',
        },
      },
      liv: {
        icon: {
          tag: 'magasinet-liv',
          name: 'liv_icon',
        },
      },
      cos: {
        icon: {
          tag: 'costume',
          name: 'cos_icon',
        },
      },
      test: {},
    },
  },
  languageSpecefic: {
    languages: {
      dk: {
        socialShare: [
          {
            type: 'link',
            link: 'https://www.facebook.com/bilmagasinet',
            icon: 'facebook',
            description: 'share on facebook',
          },
          {
            type: 'copy',
            icon: 'link',
            style: '',
            description: 'copy link',
          },
        ],
      },
    },
  },

  contentTypes: {
    article: {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: 'overlay',
        template: 'overlay1',
        isAccessibleForFree: false,
      },
    },
    'feature-default': {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: 'overlay',
        template: 'overlay1',
        isAccessibleForFree: false,
      },
    },
    'news-free': {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: false,
        template: false,
        isAccessibleForFree: true,
      },
    },
    'news-premium': {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: 'overlay',
        template: 'overlay1',
        isAccessibleForFree: false,
      },
    },
    'modal-default': {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: false,
        template: false,
        isAccessibleForFree: true,
      },
    },
    'modal-gradient': {
      layout: 'gradient',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: false,
        template: false,
        isAccessibleForFree: true,
      },
    },
    'SEO-free': {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: false,
        template: false,
        isAccessibleForFree: true,
      },
    },
    'SEO-premium': {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: 'overlay',
        template: 'overlay1',
        isAccessibleForFree: false,
      },
    },
    'feature-gradient': {
      layout: 'gradient',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: 'overlay',
        template: 'overlay1',
        isAccessibleForFree: false,
      },
    },
    'star-free': {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: false,
        template: false,
        isAccessibleForFree: true,
      },
    },
    'star-premium': {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: 'overlay',
        template: 'overlay1',
        isAccessibleForFree: false,
      },
    },
    'ask-us-free': {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: 'overlay',
        template: 'overlay1',
        isAccessibleForFree: false,
      },
    },
    audio: {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
      paywall: {
        type: 'overlay',
        template: 'overlay1',
        isAccessibleForFree: false,
      },
    },
  },
})
